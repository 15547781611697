<template>
    <div class="radio-button-group">
      <label class="radio-button-group-header">{{label}}</label>
      <div class="radio-button-group-body">
        <template v-for="option of options">  
          <input 
            type="radio" 
            :id="nativeId?option[nativeId]:option.id || option" 
            :name="label" 
            :value="nativeValue?option[nativeValue]:option.value || option.id || option" 
            :key="`${label}radioButton${nativeId?option[nativeId]:option.id || option}`"
            :checked="nativeValue?option[nativeValue] === value:option.value === value || option.id === value || option === value"
          >
          <label 
            :key="`${label}radioLabel${nativeId?option[nativeId]:option.id || option}`" 
            :for="nativeId?option[nativeId]:option.id || option"
            @click="emitNewValue(nativeValue?option[nativeValue]:option.value || option.id || option)"
          >
          {{nativeText?option[nativeText]:option.text || option.value || option.id || option}}
          </label>
        </template>
      </div>
    </div>
</template>

<script>
export default {
  name: 'radioButtonGroup',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    label: String,
    value: [String, Number],
    nativeId: String,
    nativeValue: String,
    nativeText: String,
    options: {
      type: Array,
      required: true
    },
  },
  components: {},
  mixins: [],
  data: function () {
    return {}
  },
  computed: {},
  methods: {
    emitNewValue(value) { this.$emit('change', value) }
  },
  mounted() {},
}
</script>

<style scoped>
.radio-button-group {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  margin: 10px;
}
.radio-button-group-header {
  font-weight: 700;
  margin-bottom: 5px;
}

.radio-button-group-body {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.radio-button-group-body input[type="radio"] {
  opacity: 0;
  width: 0;
}

.radio-button-group-body label {
    display: inline-block;
    background-color: rgb(209, 211, 214);
       padding: 4.625px 11.25px;
    font-size: 16px;
    margin: 5px;
    min-width: 48px
}

.radio-button-group-body label:hover {
  background-color: rgba(166, 25, 46, 0.7);
  color: white;
  cursor: pointer;
}

.radio-button-group-body input[type="radio"]:checked + label {
    background-color: rgba(166, 25, 46);
    color: white;
}
</style>
